<template>
    <div class="popovers" :style="setupPopup(color,position[0],position[1],position[2],position[3])">
        <h1 class="msTitle">{{pTitle}}</h1>
        <div class="msInfo">
        <span v-for="item in status" :key="item">
        <span>{{item.title}}<strong>{{item.value}}</strong></span>
        </span>
        </div>

        <!--- Btn For Links Start ---->
        <span v-if="!dataToggle">
          <a class="btn progressBtn" :style="styleBtn(color)"
          v-for="(item, index) in btnsContent"
          :key="item"
          :href="btnsLink[index]"
          >
          {{item}}
          </a>
        </span>


        <!--- Btn For Modal Start ---->
        <span v-if="!!dataToggle">
          <a class="btn progressBtn" :style="styleBtn(color)"
          v-for="(item, index) in btnsContent"
          :key="item"
          :data-toggle="dataToggle[index]"
          :data-target="dataTarget[index]"
          >
          {{item}}
          </a>
        </span>


      <template v-for="call in CTA" :key="call.id">
            <a class="btn progressBtn" :style="styleBtn(color)"
               v-if="call.type === 'popover'"
               data-toggle="modal"
               :data-target="'#modal'+call.id"

            >
              {{ call.call_to_action }}
            </a>
      </template>

    </div>
</template>
<script>
export default {
    name:"popover",
    props:{
        pTitle:{
            type: String,
        },
        status:{
            type: Object,
        },
        btnsContent:{
            type: Array,
        },
        btnsLink:{
            type: Array,
        },
        dataToggle:{
            type: Array,
        },
        dataTarget:{
            type: Array,
        },
        color:{
            type: String,
        },
        position:{
            type: Array,
        },
        CTA:{
          type: Object,
        }

    },
  methods:{
        styleBtn(hexCode){
            return 'background-color:'+hexCode+';border-color:'+hexCode;
        },
        setupPopup(hexCode,leftVal,topVal,rightVal,bottomVal){
            return 'left:'+leftVal+
                   ' ;top:'+topVal+
                   ' ;right:'+rightVal+
                   ' ;bottom:'+bottomVal+
                   ' ;box-shadow: inset 406px 0px 0px -400px '+hexCode+';';
        }
    }
}
</script>

<style>
.popovers {
    height: auto;
    padding: 1.3rem 1.5rem;
    position:absolute;
    background:#fff;
    border-radius: 15px;
    box-shadow: inset 406px 0px 0px -400px #82CA9C;
    transition: all 1s ease;
    min-width:15rem;
}
.popovers:hover {
    opacity: 1 !important;
}
.popovers h1 {
    font-family: Lexend;
    font-weight: 700;
    font-size: 1.2rem;
    line-height: 1rem;
    display:flex;
    color: #365061;
    margin-bottom: 15px;
}
.popovers .msInfo {
    position: relative;
    display: flex;
    color: #365061;
    max-height: 3.4rem;
    font-size:0.9rem;
}
.popovers .msInfo strong{display: block;}
.popovers .msInfo span{margin-right: 1.7rem;}
.popovers .btn.progressBtn{
    background: #82CA9C;
    border: 2px solid #82CA9C;
    border-radius: 50px;
    color: #365061;
    font-family: Lexend;
    font-weight: bold;
    font-size: 0.8rem;
    line-height: 1.1rem;
    transition: all 0.5s ease;
    margin-right:8px;
    padding:10px 12px;
}
.popovers .btn.progressBtn:hover{
    background:#fff !important;
}
/* .popovers:after{
    width: 0;
    height: 0;
    content:'';
    position: absolute;
    border-style: solid;
    border-width: 15px 15px 0 15px;
    border-color: #ffffff transparent transparent transparent;
    bottom: -15px;
    right: calc(50% - 7.5px);
} */
</style>