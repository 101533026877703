<template>
  <div class="svgContainer" ref="svgCon">
    <div class="smallHighlights" >
      <template
          v-for="plan in plans"
          v-bind:key="plan.id"
      >
        <template v-for="(dis, index) in plan.districts" :key="dis.id">
          <template v-if="index === 0">
            <h2
                v-show="hover != plan.slug"
                :style="
                        'top:'+dis.top_pos+'%; '
                        +'left:'+dis.left_pos+'%; '
                        +'right:'+dis.right_pos+'%; '
                        +'bottom:'+dis.bottom_pos+'%;'
                        "
                @mouseenter=" hover=plan.slug "
                @mouseleave="hover=''"
            >
              {{ plan.name }}</h2>
          </template>
        </template>
      </template>
    </div>
      <svg id="master_svg" viewBox="0 0 1208 848" fill="none" xmlns="http://www.w3.org/2000/svg">

        <template v-for="plan in plans" :key="plan.id">
          <template v-for="(dis, index) in plan.districts" :key="dis.id">
            <a
                :href="'plan/'+plan.slug"
                :id="plan.slug"
                @mouseenter=" hover=plan.slug "
                @mouseleave="hover=''"
                v-if="index === 0"
                v-html="dis.svg_path"
                v-bind="{fill:dis.color, stroke:dis.color}"></a>
          </template>
        </template>
      </svg>
      </div>

  <template v-for="plan in plans" :key="plan.id">
    <template v-for="(dis, index) in plan.districts" :key="dis.id">
      <popover
      :pTitle="plan.name"
      :status="dis.district_info"
      :btnsContent="['More Information']"
      :btnsLink="['plan/'+plan.slug]"
      :color="dis.color"
      :position="[
          (dis.left_pos) ?  dis.left_pos-15+'%'  : '' ,
          (dis.top_pos) ?  dis.top_pos-15+'%'  : '' ,
          (dis.right_pos) ?  dis.right_pos-15+'%'  : '' ,
          (dis.bottom_pos) ?  dis.bottom_pos-15+'%'  : ''
          ]"
      v-show="index === 0 && hover === plan.slug "
      @mouseenter=" hover = plan.slug "
      @mouseleave=" hover='' "
      />
    </template>
  </template>


  <!--        <popover-->
  <!--        pTitle="MVIII"-->
  <!--        :status="{-->
  <!--            '1':['strongL','Delivery Starting', '2023' ],-->
  <!--            '2':['strongL','Altitude', '+265' ]-->
  <!--        }"-->
  <!--        :btnsContent="['More Information']"-->
  <!--        :btnsLink="['/cluster/mviii']"-->
  <!--        color="#B692DE"-->
  <!--        :position="['2vw','10vh']"-->
  <!--        v-if=" hover == 'mviii' "-->
  <!--        @mouseenter=" hover='mviii' "-->
  <!--        @mouseleave="hover=''"-->
  <!--        />-->
  <!--        <popover-->
  <!--        pTitle="MV Park Island"-->
  <!--        :status="{-->
  <!--            '1':['strongL','Delivery Starting', '2024' ],-->
  <!--            '2':['strongL','Altitude', '+265' ]-->
  <!--        }"-->
  <!--        :btnsContent="['More Information']"-->
  <!--        :btnsLink="['/cluster/mvisland']"-->
  <!--        color="#F1C40F"-->
  <!--        :position="['15%','26%']"-->
  <!--        v-if=" hover == 'mvIsland' "-->
  <!--        @mouseenter=" hover='mvIsland' "-->
  <!--        @mouseleave="hover=''"-->
  <!--        />-->
  <!--        <popover-->
  <!--        pTitle="Mountain Park"-->
  <!--        :status="{-->
  <!--            '1':['strongL','Delivery Starting', '2025' ],-->
  <!--            '2':['strongL','Altitude', '+300' ]-->
  <!--        }"-->
  <!--        :btnsContent="['More Information']"-->
  <!--        :btnsLink="['/cluster/mp']"-->
  <!--        color="#BAE1FF"-->
  <!--        :position="['5vw','33vh']"-->
  <!--        v-if=" hover == 'mpark' "-->
  <!--        @mouseenter=" hover='mpark' "-->
  <!--        @mouseleave="hover=''"-->
  <!--        />-->
  <!--        <popover-->
  <!--        pTitle="Club Park"-->
  <!--        :status="{-->
  <!--            '1':['strongL','Delivery Started', '2020' ],-->
  <!--            '2':['strongL','Phase1', 'Livable' ]-->
  <!--        }"-->
  <!--        :btnsContent="['More Information']"-->
  <!--        :btnsLink="['/cluster/cd']"-->
  <!--        color="#FFD5A5"-->
  <!--        :position="['25%','45%']"-->
  <!--        v-if=" hover == 'club' "-->
  <!--        @mouseenter=" hover='club' "-->
  <!--        @mouseleave="hover=''"-->
  <!--        />-->
  <!--        <popover-->
  <!--          pTitle="Lagoon Beach Park"-->
  <!--          :status="{-->
  <!--            '1':['strongL','Delivery Date', '2025' ]-->
  <!--          }"-->
  <!--          :btnsContent="['More Information']"-->
  <!--          :btnsLink="['/cluster/lagoon']"-->
  <!--          color="#FB5012"-->
  <!--          :position="['45%','40%']"-->
  <!--          v-if=" hover == 'lagoon' "-->
  <!--          @mouseenter=" hover='lagoon' "-->
  <!--          @mouseleave="hover=''"-->
  <!--        />-->
  <!--        <popover-->
  <!--        pTitle="Heartwork"-->
  <!--        :status="{-->
  <!--            '1':['strongL','Delivery Starting', '2024' ],-->
  <!--        }"-->
  <!--        :btnsContent="['More Information']"-->
  <!--        :btnsLink="['/cluster/hw']"-->
  <!--        color="#F56E7A"-->
  <!--        :position="['66%','37vh']"-->
  <!--        v-if=" hover == 'heart' "-->
  <!--        @mouseenter=" hover='heart' "-->
  <!--        @mouseleave="hover=''"-->
  <!--        />-->
   <span class="mobileMessage">* Click on highlighted areas to view updates</span>
</template>

<script>
import popover from "@/components/general/popover.vue";
import {mapGetters, mapState} from 'vuex';
export default {
    
    name:"masterHighlights",
    components:{
        popover
    },
    computed:{
      ...mapGetters(['getImage']),
      ...mapState(['plans'])
    },
    data(){
      return{
        hover:'',
      }
    },
    watch:{
        hover(newValue,oldValue){
          newValue ? document.getElementById(newValue).style.opacity = 1 : document.getElementById(oldValue).style.opacity = 0 ;
        }
    }
    
}
</script>

<style scoped>
.mobileMessage{display:none;font-size:1.25rem;font-weight:600;margin-top:15px;}
.smallHighlights{transition: all 0.5s ease;}
.smallHighlights h2{
    color:white !important;
    max-width: 6vw;
    padding:10px;
    text-align: center;
    font-size:1.1rem;
    line-height:1rem;
    position:absolute;
  background-color: rgba(42, 90, 112, 0.7);
  border-radius: 50px;
}
.svgContainer {
    position: absolute;
    max-width: 55vw !important;
    width: 100%;
    height: auto;

    }
svg {
    width: 100%;
    height: 100%;
}
svg a {
    opacity:0;
    transition: all 0.5s;
}

/* ------------ Lagoon Text Placeholder ----------- */
.LagoonPlaceHolder{
    position:absolute;
    top:48%;
    right:28%;
    width: auto !important;
    height: auto !important;
    color:white;
}
.LagoonPlaceHolder h3{
    margin-top:5px;
    font-size:1.2rem;
    font-weight: 700;
}
.LagoonPlaceHolder p{
    font-size:0.9rem;
}

/* ------------ Lagoon Text Placeholder ----------- */
</style>